<template>
  <v-dialog
      v-model="forgotPasswordDialog"
      max-width="500"
      persistent
  >
    <Message/>
    <template v-slot:activator="{ on, attrs }">
      <v-hover v-slot="{ hover }">
        <a v-bind="attrs" v-on="on" @click="popupForgot = true"
           :style="{'text-decoration-line': hover ? 'underline' : 'none'}"
           style="font-size: 16px; color: #eaedff; font-style: italic">
          Forgot Your Password?</a>
      </v-hover>
    </template>

    <v-card>
      <v-card-text class="py-4 px-8" style="height: 250px; background-color: #3b4559">
        <v-btn style="position: absolute; top: 10px; right: 10px" icon small @click="forgotPasswordDialog = false">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <v-layout style="height: 50px" align-center justify-center>
          <span style="font-size: 25px; font-weight: bold">
            Can't access your account?
          </span>
        </v-layout>
        <v-form @submit="forgotPassword" onSubmit="return false;" v-model="valid" ref="formData"
                style="margin-top: 20px">
          <v-layout style="height: 70px" align-center>
            <div style="height: 100%; width: 60px" class="pt-3">
              <v-icon size="20" color="secondary">mdi-information</v-icon>
            </div>
            <span>
              If you can't access Monitoring, fill in this form and an email will be sent to you with the details to access your account again.
            </span>
          </v-layout>
          <div style="font-size: 18px">
            Email:
          </div>
          <v-text-field
              light
              flat
              solo
              dense
              background-color="white"
              v-model="email"
              label="Email"
              class="mt-2"
              :rules="[rules.requiredEmail]"></v-text-field>
        </v-form>
      </v-card-text>
      <v-card-actions style="background-color: #3b4559" class="pb-6 px-8">
        <v-spacer/>
        <v-btn width="120" color="secondary" @click="forgotPassword" dark>Send</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import {
  forgotPassword
} from "@/backend";
import Message from "@/components/Message";

export default {
  name: "PopupCreateUser",
  components: {Message},
  data() {
    return {
      loading: false,
      forgotPasswordDialog: false,
      valid: false,
      email: undefined,
      rules: {
        requiredEmail: value => (!!value && !!value.trim()) || 'Email is required',
      }
    }
  },
  mounted() {
  },
  watch: {
    forgotPasswordDialog(val) {
      if (val) {
        this.email = undefined
        if (this.$refs.formData) this.$refs.formData.resetValidation()
      }
    }
  },
  methods: {
    async forgotPassword() {
      this.$refs.formData.validate()
      if (this.valid) {
        this.loading = true
        try {
          const res = await forgotPassword({
            "email": this.email,
          })
          this.$store.commit('message/SHOW_SUCCESS', res.data.message)
          this.forgotPasswordDialog = false
        } catch (e) {
        } finally {
          this.loading = false
        }
      }
    }
  }
}
</script>

<style scoped>

</style>
