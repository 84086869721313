
export default {
  namespaced: true,
  state: {
    zoomLevel: 0,
    detectFields: [],
    isDetect: false,
  },

  mutations: {
  }
}