<template>
  <v-app>
    <Message/>
    <div style="height: 100vh">
      <div class="d-flex flex-column" style="height: 100%;">
        <div
            class="d-flex flex"
            style="background-color: #ffffff"
        >
          <v-layout
              align-center
              class="fill-height"
              :style="{'background-image': `url(${require('@/assets/images/wallpaper.png')}`}"
              style="background-size: 100% 100%"
          >
            <v-container style="max-height: 100%;" class="py-2 overflow-y-auto register-content">
              <v-layout class="fill-height elevation-2 py-6" column
                        style="border-radius: 10px; background-color: rgb(45 47 49 / 50%)">
                <div
                    style="flex: none; width: 100%; height: 60px; padding-left: 50px; padding-right: 50px;">
                  <div class="d-flex fill-height align-center justify-center">
                    <span style="font-weight: 700; font-size: 35px; color: #BD6760;">Register</span>
                  </div>
                </div>
                <v-form @submit="submitRegister" onSubmit="return false" style="width: 100%" v-model="valid"
                        ref="formRegister">
                  <v-layout class="fill-height">
                    <div class="flex fill-height" style="flex: 1;">
                      <div class="d-flex align-center fill-height flex-wrap" style="width: 100%">
                        <div class="d-flex align-start justify-end fill-height" style="width: 100%">
                          <div style="width: 100%; height: 100%;">
                            <div class="d-flex flex-column fill-height">
                              <div class="d-flex flex-column flex pt-4">
                                <div class="user-info">
                                  <div class="user-data-left">
                                    <span style="font-size: 18px; font-weight: bold;; color: #dddddd">Name</span>
                                    <div style="width: 100%;" class="pt-1">
                                      <v-text-field
                                          light
                                          class="input-login"
                                          height="50"
                                          dense
                                          background-color="#00000042"
                                          solo
                                          flat
                                          v-model="name"
                                          label="Name"
                                          style="color: black !important;"
                                          :rules="[rules.requiredUserName]"
                                      >
                                      </v-text-field>
                                    </div>
                                    <span style="font-size: 18px; font-weight: bold;; color: #dddddd">Email</span>
                                    <div style="width: 100%;" class="pt-1">
                                      <v-text-field
                                          light
                                          class="input-login"
                                          height="50"
                                          dense
                                          background-color="#00000042"
                                          solo
                                          flat
                                          v-model="email"
                                          label="Email"
                                          style="color: black !important;"
                                          :rules="emailRules"
                                      >
                                      </v-text-field>
                                    </div>
                                  </div>
                                  <div class="user-data-right">
                                    <span style="font-size: 18px; font-weight: bold;; color: #dddddd">Password</span>
                                    <div style="width: 100%;" class="pt-1">
                                      <v-text-field
                                          light
                                          class="input-login"
                                          height="50"
                                          dense
                                          background-color="#00000042"
                                          solo
                                          flat
                                          v-model="password"
                                          label="Password"
                                          :rules="[rules.requiredPassword]"
                                          :type="isShowPassword ? 'text' : 'password'"
                                          :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                          @click:append="isShowPassword = !isShowPassword">
                                      </v-text-field>
                                    </div>
                                    <span
                                        style="font-size: 18px; font-weight: bold;; color: #dddddd">Confirm Password</span>
                                    <div style="width: 100%;" class="pt-1">
                                      <v-text-field
                                          light
                                          class="input-login"
                                          height="50"
                                          dense
                                          background-color="#00000042"
                                          solo
                                          flat
                                          v-model="confirmPassword"
                                          label="Confirm Password"
                                          :rules="[rules.requiredConfirmPassword]"
                                          :type="isShowPassword ? 'text' : 'password'"
                                          :disabled="!this.password"
                                          :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                          @click:append="isShowPassword = !isShowPassword">
                                      </v-text-field>
                                    </div>
                                  </div>
                                </div>
                                <div style="flex: none; padding-left: 40px; padding-right: 40px; padding-bottom: 10px;">
                                  <div class="g-recaptcha" id="captcha"></div>
                                  <div style="width: 100%; height: 70px" class="pt-1">
                                    <v-layout class="fill-height align-end">
                                      <v-btn :disabled="loading" @click="goToLink('/signIn')" color="button" width="120"
                                             class="mr-2">
                                        <v-icon class="mr-1">mdi-home</v-icon>
                                        Sign In
                                      </v-btn>
                                      <v-spacer/>
                                      <!--                                      <v-btn light :disabled="loading" width="120" class="mr-2" color="cancel"-->
                                      <!--                                             @click="resetData">-->
                                      <!--                                        <v-icon class="mr-1">mdi-close</v-icon>-->
                                      <!--                                        Cancel-->
                                      <!--                                      </v-btn>-->
                                      <v-btn :loading="loading" color="secondary" width="120" type="submit">
                                        <v-icon class="mr-1">mdi-check</v-icon>
                                        Submit
                                      </v-btn>
                                    </v-layout>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </v-layout>
                </v-form>
              </v-layout>
            </v-container>
          </v-layout>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import {
  register,
  getCountry, checkToken
} from "@/backend"
import {mapState} from "@/store/ults"
import sleep from "@/ultis/sleep"
import config from '@/config.json'
import Message from "@/components/Message"
import api from "@/api"

export default {
  components: {Message},
  data() {
    return {
      verified: false,
      loading: false,
      valid: false,
      popupForgot: false,
      rules: {
        requiredUserName: value => (!!value && !!value.trim()) || 'Name is required',
        requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
        requiredConfirmPassword: value => (!!value && !!value.trim() && value.trim() === this.password.trim()) || 'Password is invalid'
      },
      emailRules: [
        v => !!v || 'E-mail is required',
        v => /.+@.+\..+/.test(v) || 'E-mail must be valid',
      ],
      name: undefined,
      confirmPassword: undefined,
      email: undefined,
      password: undefined,
      isShowPassword: false
    }
  },
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  created() {
    const token = api.getToken()
    if (token) this.checkTokenExist()
  },
  mounted() {
    this.initReCaptcha()
  },
  methods: {
    initReCaptcha() {
      setTimeout(function () {
        if (typeof grecaptcha === 'undefined' || typeof grecaptcha.render === 'undefined') {
          this.initReCaptcha()
        } else {
          grecaptcha.render('captcha', {
            sitekey: config.SITE_KEY
          })
        }
      }.bind(this), 100)
    },
    async submitRegister() {
      this.$refs.formRegister.validate()
      if (!grecaptcha.getResponse()) return (this.$store.commit('message/SHOW_ERROR', 'Function not verified'))
      if (!this.valid) return
      try {
        this.loading = true
        await register({
          "email": this.email,
          "name": this.name,
          "password": this.password,
          "password_confirmation": this.confirmPassword
        })
        this.$store.commit('message/SHOW_SUCCESS', 'Success')
        await sleep(200)
        location.href = `${location.origin}/signIn`
      } catch (e) {
      } finally {
        this.loading = false
      }
    },
    resetData() {
      this.name = undefined
      this.confirmPassword = undefined
      this.email = undefined
      this.password = undefined
      this.$refs.formRegister.resetValidation()
    },
    async checkTokenExist() {
      try {
        await checkToken()
        location.href = `${location.origin}/app`
      } catch (e) {
        api.deleteToken()
      }
    },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({path: link});
    }
  }
};
</script>
<style scoped>
/deep/
.input-login .v-input__slot {
  /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);*/
  color: black !important;
}

/deep/
.input-login v-input {
  color: black !important;
}

/deep/
.input-login input {
  color: #a8a8a8 !important;
}

/deep/
.input-login label {
  color: #a8a8a8 !important;
}

.user-info {
  flex: none;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 10px;
}

.user-data-left {
  width: 100%;
}

.user-data-right {
  width: 100%;
}

.register-content {
  width: 500px;
}

@media only screen and (max-height: 750px) {
  .user-data-left {
    padding-right: 20px;
    width: 100%;
  }
  .user-data-right {
    padding-left: 20px;
    width: 100%;
  }
  .user-info {
    display: flex;
    width: 100%;
    padding-left: 40px;
    padding-right: 40px;
    padding-bottom: 10px;
  }
  .register-content {
    width: 80vw;
  }
}

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}

.before-enter {
  opacity: 0;
  transform: scale(.5) rotateZ(-25deg);
  transition: all 1s ease-out;
}

.enter {
  opacity: 1;
  transform: scale(1) rotateZ(0deg);
}

.header {
  height: 800px;
}

.content {
  height: 100vh
}

.join-button {
  display: flex;
  min-width: 0;
  min-height: 0;
}

.left-info {
  display: flex;
  min-width: 0;
  min-height: 0;
}

@media only screen and (max-width: 925px) {
  .left-info {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .join-button {
    display: none;
  }
}

@media only screen and (max-height: 800px) {
  .header {
    height: 700px;
  }
}
</style>
<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}

.main-layout .custom-list-item {
  padding-left: 30px;
}

.flex {
  min-width: 0;
  min-height: 0;
}

.flex-none {
  flex: none;
}
</style>
<style>
html,
body,
.app {
}
</style>
