<template>
  <v-app style="height: 100vh">
    <v-overlay :value="loading" absolute>
      <v-progress-circular
          indeterminate
          size="64"
      ></v-progress-circular>
    </v-overlay>
    <div style="height: 100%">
      <!--      :style="{'background-image': `url(${require('@/assets/images/bau-troi-day-sao.webp')}`}"-->
      <!--      <div class="header" style="width: 100%">-->
      <!--        <img src="https://wallpaperaccess.com/full/123112.jpg" width="100%" class="image-header" style="object-fit: cover">-->
      <!--      </div>-->
      <div class="d-flex flex-column overflow-y-auto">
        <div style="height: 75px; width: 100%; position: absolute; top: 0; right: 0; z-index: 3">
          <v-layout class="fill-height px-5" align-center>
            <img alt="bgk" :src="require('@/assets/images/logo-white-2a642acb6b7ceb83fae385c1c7524d5f.png')" height="50"/>
            <v-spacer/>
            <v-btn text dark @click="login" v-if="!currentUser">Sign In</v-btn> <span v-if="!currentUser">|</span>
            <v-btn text dark @click="goToLink('/register')" v-if="!currentUser">Register</v-btn>
            <div style="width: 200px" v-else class="d-flex align-center">
              <v-btn style="border: 2px solid #fff" text dark @click="goToLink('/app')" class="mr-4">Application</v-btn>
              <AvatarPopOver></AvatarPopOver>
            </div>
          </v-layout>
        </div>
        <div style="height: 30px; width: 100%; position: absolute; bottom: 0; right: 0; z-index: 3">
          <v-layout class="fill-height px-8" align-center style="color: #d2d0d0" justify-end>
            Presented by: <a class="px-2" style="text-decoration: none; color: #0379cd" href="https://skymapglobal.com">SkymapGlobal.com</a> | Email: Support@eofactory.ai
          </v-layout>
        </div>
        <div style="width: 100%; height: fit-content; position: relative">
          <div
              style="width: 100%; background-color: rgba(0,0,0,0.7); position: absolute; top: 0; left: 0; height: 100vh; filter: blur(12px)"
              class="d-flex align-center justify-center">
          </div>
          <div class="header-left">
            <span style="font-size: 65px; font-weight: 800; color: #FF921E;  line-height: 70px">
  <span style="text-transform: uppercase; font-size: 26px; color: #47BAEB">Welcome</span>
  <br>
  Management
  <br>
  <span style="font-size: 60px; font-weight: bold; color: #47BAEB; margin-left: 360px">
    &
  </span>
  <br>
  <span style="font-weight: 800; color: #79C843; line-height: 75px; margin-left: 360px">
    Monitoring
  </span>
</span>
            <br>
            <div style="width: 680px; padding-top: 95px">
              <v-carousel height="200" show-arrows-on-hover>
                <v-carousel-item v-for="(slide, i) in slides" :key="i">
                  <v-layout column align-center justify-center style="padding: 0 60px">
                    <h2>{{ slide.header }}</h2>
                    <span class="mt-2" style="text-align: justify">
                      {{ slide.content }}
                    </span>
                  </v-layout>
                </v-carousel-item>
              </v-carousel>
              <!--              <span style="max-width: 500px; font-size: 18px">EOfactory allows you to use your own data, or subscribe to our data delivery services. Our platform supports multi public and private data sources that can be used to analyse and extract information.</span>-->
            </div>
            <img
                alt="header-img"
                :src="require('@/assets/images/right_header_160x144.png')"
                style="object-fit: cover; width: 180px; height: auto; position: absolute; top: 70px; right: 55px">
            <img
                alt="header-img"
                :src="require('@/assets/images/Agriculture.png')"
                class="mt-4"
                style="object-fit: cover; width: 300px; height: auto; position: absolute; top: 160px; left: 0">
          </div>
          <div class="header-right">
            <img
                alt="header-img"
                :src="require('@/assets/images/analytics.png')"
                class="mt-4 left-img"
                style="object-fit: cover;">
          </div>
          <img alt="farm" :src="require('@/assets/images/farm_ld.jpg')" width="100%"
               style="max-height: 100%; object-fit: cover;">
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import AvatarPopOver from "@/components/AvatarPopOver"
import {mapState} from "@/store/ults"
import {checkToken} from "@/backend"
import Store from "@/store"

export default {
  components: {
    AvatarPopOver
  },
  data: () => ({
    loading: false,
    slides: [
      // {
      //   header: 'Manage your fields remotely',
      //   content: 'Monitor the state of your crops right from the office, learn about the slightest changes on-the-spot, and make fast and reliable decisions on field treatment'
      // },
      // {
      //   header: 'Save on costs by up to 30% with VRA approach',
      //   content: 'This tool can identify up to 7 zones with different productivity rates and create differential fertilizing, sowing, and irrigation maps, thus saving you a lot of time and resources.'
      // }
    ],
  }),
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  created() {
  },
  mounted() {
    this.getMe()
  },
  methods: {
    async getMe() {
      try {
        this.loading = true
        const res = await checkToken()
        Store.commit('auth/SET_USER', res.data)
      } catch (e) {

      } finally {
        this.loading = false
      }
    },
    login() {
      location.href = `${location.origin}/signIn`
    },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({path: link});
    }
  }
};
</script>
<style scoped>
/deep/
.v-carousel__controls {
  background-color: transparent !important;
}

.header-left {
  position: absolute;
  top: 150px;
  left: 250px;
  color: white
}

.header-right {
  position: absolute;
  top: 150px;
  right: 250px;
  color: white
}

.left-img {
  height: 550px;
  width: auto
}

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}

.before-enter {
  opacity: 0;
  transform: scale(.5) rotateZ(-25deg);
  transition: all 1s ease-out;
}

.enter {
  opacity: 1;
  transform: scale(1) rotateZ(0deg);
}

.header {
  height: 800px;
}

.content {
  height: 100vh
}

.join-button {
  display: flex;
}

@media only screen and (max-width: 1100px) {
  .join-button {
    display: none;
  }
}

@media only screen and (max-width: 1500px) {
  .header-left {
    position: absolute;
    top: 150px;
    left: 50px;
    color: white
  }

  .header-right {
    position: absolute;
    top: 150px;
    right: 50px;
    color: white
  }
}

@media only screen and (max-height: 800px) {
  .header-left {
    position: absolute;
    top: 80px;
    color: white
  }

  .header-right {
    position: absolute;
    top: 80px;
    color: white
  }

  .left-img {
    height: 450px;
    width: auto
  }

  .header {
    height: 700px;
  }
}
</style>
<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}

.main-layout .custom-list-item {
  padding-left: 30px;
}
</style>
<style>
html,
body,
.app {
}
</style>
