import Vue from 'vue'
import Vuex from 'vuex'
import auth from "@/store/modules/auth"
import main from "@/store/modules/main"
import message from "@/store/modules/message"
import legend from "@/store/modules/legend"
import point from "@/store/modules/point"
import dataset from "@/store/modules/dataset"
import dashboard from "@/store/modules/dashboard.js"
import record from "@/store/modules/record"
import tabs from "@/store/modules/tabs"
import map from "@/store/modules/map"
import {s} from './ults'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
  },
  getters: {
  },
  mutations: {
    s,
  },
  actions: {
    s
  },
  modules: {
    namespaced: true,
    auth,
    main,
    dataset,
    message,
    legend,
    point,
    dashboard,
    record,
    tabs,
    map
  }
})
