<template>
  <v-card width="350" height="470" style="border-radius: 6px; background-color: #282828" class="elevation-4" v-show="showPopup">
    <v-layout class="fill-height" style="border-radius: 6px" column>
      <div
          style="flex: none; height: 50px; width: 100%; border-bottom: 1px solid #393939; border-top-left-radius: 6px; border-top-right-radius: 6px">
        <v-layout class="pa-2 fill-height" align-center>
          <v-icon class="mr-2">mdi-message-badge</v-icon>
          <h4>Feedback</h4>
          <v-spacer/>
          <v-btn icon small @click="showPopup = false">
            <v-icon>mdi-chevron-down</v-icon>
          </v-btn>
        </v-layout>
      </div>
      <v-layout class="fill-height pa-3 overflow-y-auto" ref="feedbackContent" column>
        <div style="flex: none; height: 150px">
          <b style="font-size: 15px">Contact information:</b>
          <div style="font-size: 14px" class="pt-2">
            <v-icon size="20" class="mr-2">mdi-map-marker-radius</v-icon>
            <b class="mr-1">Address:</b>
            18 Howard Road # 07-02 Novelty Bizcentre,
            Singapore 369585
          </div>
          <div style="font-size: 14px" class="pt-2">
            <v-icon size="20" class="mr-2">mdi-email</v-icon>
            <b class="mr-1">Email:</b>
            Support@eofactory.ai
          </div>
          <div style="font-size: 14px" class="pt-2 pb-3">
            <v-icon size="20" class="mr-2">mdi-phone</v-icon>
            <b class="mr-1">Phone:</b>
            +65 6286 0018
          </div>
        </div>
        <v-divider/>
        <b style="font-size: 15px" class="my-3">Feedback message:</b>
        <div style="flex: none; width: 100%" v-for="(item, index) in feedback">
          <v-layout class="py-2" align-center :class="item.isSystem ? 'justify-start': 'justify-end'">
            <div
                v-if="item.isImg"
                :style="{'background-color': item.isSystem ? 'rgba(121,121,121,0.98)' : '#0379cd'}"
                style="max-width: 70%; border-radius: 12px; font-size: 14px"
                class="pa-2">
              <img :src="item.src" height="100%" width="100%" alt="icon"/>
            </div>
            <div
                v-else
                :style="{'background-color': item.isSystem ? 'rgba(121,121,121,0.98)' : '#0379cd'}"
                style="max-width: 70%; border-radius: 12px; font-size: 14px"
                class="pa-2">
              {{ item.message }}
            </div>
          </v-layout>
        </div>
      </v-layout>
      <v-card
          style="flex: none; min-height: 50px; width: 100%; border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; background-color: #282828">
        <v-layout class="pa-1 fill-height" style="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px"
                  column justify-center>
          <div style="height: 70px; width: 100%; border-bottom: 1px solid #777c85; overflow-x: auto"
               v-if="images.length">
            <v-layout class="fill-height" align-center>
              <div
                  v-for="(item, index) in images"
                  style="width: 50px; height: 50px; border: 1px solid white; border-radius: 6px; position: relative"
                  class="ma-2">
                <v-btn @click="images.splice(index, 1)" icon x-small
                       style="position: absolute; top: -10px; right: -10px">
                  <v-icon color="red">mdi-close-circle</v-icon>
                </v-btn>
                <v-layout class="fill-height pa-4" align-center justify-center>
                  <img alt="img" :src="item.src" width="38" height="38" style="object-fit: cover;">
                </v-layout>
              </div>
            </v-layout>
          </div>
          <v-layout
              :class="images.length ? 'pt-2' : ''"
              style="border-bottom-left-radius: 6px; border-bottom-right-radius: 6px; height: 50px" align-center>
            <UploadImage @change="covertBase64"/>
            <!--            <v-btn icon small class="mr-2">-->
            <!--              <v-icon>mdi-image-plus</v-icon>-->
            <!--            </v-btn>-->
            <v-text-field ref="message" @keyup.enter="sendFeedback" v-model="message" label="Message"
                          placeholder="Message" solo-inverted hide-details dense/>
            <v-btn icon class="ml-2" @click="sendFeedback">
              <v-icon size="22">mdi-send</v-icon>
            </v-btn>
          </v-layout>
        </v-layout>
      </v-card>
    </v-layout>
  </v-card>
</template>

<script>
import sleep from "@/ultis/sleep"
import UploadImage from "@/components/home/feedback/UploadImage";

export default {
  name: "Feedback",
  components: {UploadImage},
  data() {
    return {
      images: [],
      feedback: [],
      message: undefined
    }
  },
  props: {
    isShow: {type: Boolean, default: false}
  },
  computed: {
    showPopup: {
      get() {
        return this.isShow
      },
      set(val) {
        this.$emit('update:isShow', val)
      }
    }
  },
  methods: {
    covertBase64(files) {
      this.images = []
      files.forEach(async (file) => {
        this.images.push({src: await this.toBase64(file)})
      })
    },
    toBase64(file) {
      return new Promise((resolve, reject) => {
        const reader = new FileReader()
        reader.readAsDataURL(file)
        reader.onload = () => resolve(reader.result)
        reader.onerror = error => reject(error)
      })
    },
    async sendFeedback() {
      this.images.forEach(image => {
        this.feedback.push({
          isSystem: false,
          isImg: true,
          src: image.src,
          message: ''
        })
      })
      this.images = []
      if (this.message) this.feedback.push({
        isSystem: false,
        message: this.message
      })
      this.message = undefined
      // this.$refs.message.blur()
      if (!this.feedback.some(val => val.isSystem)) {
        await sleep(1000)
        this.feedback.push({
          isSystem: true,
          message: 'All your feedback will be sent to our support department'
        })
        await sleep(500)
        this.feedback.push({
          isSystem: true,
          message: 'Thank you for your feedback!!'
        })
        await sleep(0)
        this.scrollToBottom()
      } else {
        await sleep(0)
        this.scrollToBottom()
      }
    },
    scrollToBottom() {
      let objDiv = this.$refs.feedbackContent
      objDiv.scrollTop = objDiv.scrollHeight
    }
  }
}
</script>

<style scoped>

</style>
