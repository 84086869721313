<template>
  <div>
    <v-btn icon class="mr-2" @click="selectFile">
      <v-icon size="22">mdi-image-plus</v-icon>
    </v-btn>
    <input
        multiple
        ref="file-input"
        type="file"
        accept=".jpg, .jpeg, .png"
        style="display: none"
        @change.prevent="handleInputFiles($refs['file-input'].files)"
    />
  </div>
</template>

<script>
export default {
  name: "UploadImage",
  data() {
    return {
      files: []
    };
  },
  methods: {
    handleInputFiles(files) {
      if (files.length === 0) return
      this.files = Object.values(files)
      this.$emit("change", this.files)
      this.$refs["file-input"].value = ""
    },
    selectFile() {
      this.$refs['file-input'].click()
    },
  }
}
</script>

<style scoped>

</style>
