import api from '@/api'
import config from '@/config.json'

export async function authLogin(data) {
    let result = await api.post(`${config.backend}/auth/login`, data, {}, true)
    api.setToken("Bearer " + result.data.access_token)
    return result
}

export async function checkToken() {
    return await api.get(`${config.backend}/auth/me`, {}, {}, false)
}

export async function authLogout() {
    await api.post(`${config.backend}/auth/logout`)
    api.deleteToken()
    window.location.href = '/'
}

export async function resetPassword(data) {
    return await api.post(`${config.backend}/auth/password/reset`, data)
}

export async function register(data) {
    return await api.post(`${config.backend}/auth/register`, data)
}

export async function getCountry() {
    return await api.get(`${config.backend}/territories/countries`)
}

export async function getState() {
    return await api.get(`${config.backend}/territories/states`)
}

export async function getDistrict(data) {
    return await api.get(`${config.backend}/territories/districts`, data)
}

export async function getSubDistrict(data) {
    return await api.get(`${config.backend}/territories/sub-districts`, data)
}

export async function getRole() {
    return await api.get(`${config.backend}/config/roles`)
}

export async function updateProfile(data) {
    return await api.post(`${config.backend}/profile`, data)
}

//user//
export async function getAllUser(data) {
    return await api.get(`${config.backend}/admin/users`, data)
}

export async function createUser(data) {
    return await api.post(`${config.backend}/admin/users`, data)
}

export async function updateUser(form) {
    return await api.put(`${config.backend}/admin/users/${form.id}`, form.data)
}

export async function deleteUser(id) {
    return await api.delete(`${config.backend}/admin/users/${id}`)
}

export async function getDataDashBoard(data) {
    return await api.get(`${config.backend}/imageries/statistics`, data)
}

export async function forgotPassword(data) {
    return await api.post(`${config.backend}/auth/password/email`, data)
}

export async function getLegend() {
    return await api.get(`${config.backend}/indices`)
}


export async function getCurrentBbox() {
    return await api.get(`${config.backend}/map/bbox`)
}

export async function getField(data) {
    return await api.get(`${config.backend}/fields`, data)
}

export async function getCluster() {
    return await api.get(`${config.backend}/map/fields`, {}, {timeout: 1000 * 10 * 60})
}

export async function getImages(data) {
    return await api.get(`${config.backend}/map/data`, data, {timeout: 10 * 60 * 1000})
}

// export async function getImages(data) {
//   return await api.get(`${config.backend}/map/images`, data, )
// }

export async function getChartData(form) {
    return await api.get(`${config.backend}/fields/${form.id}/images`, form.data, {timeout: 10 * 60 * 1000})
}

export async function createField(data) {
    return await api.post(`${config.backend}/fields`, data)
}

export async function getFieldInfo(id) {
    return await api.get(`${config.backend}/fields/${id}`, {}, {timeout: 2 * 60 * 1000})
}

export async function updateField(form) {
    return await api.put(`${config.backend}/fields/${form.id}`, form.data)
}

export async function deleteField(id) {
    return await api.delete(`${config.backend}/fields/${id}`)
}

export async function getRecords(form) {
    return await api.get(`${config.backend}/survey/records`, form, {timeout: 2 * 60 * 1000})
}

export async function getGroup() {
    return await api.get(`${config.backend}/field/groups`)
}

export async function calculateZoning(params) {
    return await api.post(`${config.backend}/fields/${params.id}/zones`, params.data)
}

export async function saveZone(params) {
    return await api.put(`${config.backend}/fields/${params.fieldId}/zones/${params.id}`, params.data)
}

export async function getmap(id) {
    return await api.get(`${config.backend}/fields/${id}/zones`, {perpage: 'all'})
}

export async function getMapDetail(params) {
    return await api.get(`${config.backend}/fields/${params.fieldId}/zones/${params.id}`)
}

export async function deleteMap(params) {
    return await api.delete(`${config.backend}/fields/${params.fieldId}/zones/${params.id}`)
}

export async function searchLocation(keyword) {
    return await api.get(`${config.backendPHPEofactory}/adminitrative/autocomplete?search=${keyword}`)
}

export async function applyDetect(params) {
    return await api.post(`${config.backend}/fields/${params.id}/update-crop`, params.data)
}

// export async function connectSurvey(data) {
//   return await api.post(`${config.surveyHost}/auth/link-account`, data)
// }

export async function linkSurvey(data) {
    return await api.post(`${config.backend}/auth/link-survey-account`, data)
}

export async function detectFarm(data) {
    return await api.post(`${config.backend}/v1/submit`, data, {}, true, {})
}

export async function getDisease(id) {
    return await api.get(`${config.backend}/fields/${id}/diseases`, {}, {timeout: 1000 * 10 * 60})
}




