<template>
  <v-app>
    <Message/>
    <div style="height: 100vh">
      <div class="d-flex flex-column" style="height: 100%;">
        <!--        <div-->
        <!--            style="flex: none; width: 100%; height: 76px; background: linear-gradient(180deg, rgba(208, 202, 216, 0.8) 0%, rgba(228, 216, 227, 0.8) 41.55%, rgba(231, 223, 232, 0.8) 72.28%, rgba(231, 227, 233, 0.8) 100%); flex: none">-->
        <!--          <v-layout align-center class="fill-height px-10">-->
        <!--            <h3 style="color: #43a047; cursor: pointer">URBAN GREEN DATA MANAGEMENT</h3>-->
        <!--          </v-layout>-->
        <!--        </div>-->
        <div
            class="d-flex flex"
            style="background-color: #ffffff"
        >
          <v-layout
              align-center
              justify-center
              class="fill-height"
              :style="{'background-image': `url(${require('@/assets/images/wallpaper.png')}`}"
              style="background-size: 100% 100%"
          >
            <div style="width: 475px">
              <div class="d-flex align-center flex-wrap"
                   style="width: 475px; height: 600px; background-color: rgb(45 47 49 / 50%); border-radius: 10px">
                <div style="width: 100%; height: 100%;">
                  <div class="d-flex align-start justify-end fill-height">
                    <div style="width: 100%; height: 100%; border-radius: 10px" class="elevation-2">
                      <div class="d-flex flex-column fill-height pt-9" style="border-radius: 10px">
                        <div
                            class="pb-5 mb-7 mt-3"
                            style="flex: none; width: 100%; height: 60px; padding-left: 50px; padding-right: 50px; border-radius: 10px; text-align: center">
                          <div class="d-flex fill-height align-center justify-center">
                            <div
                                style="font-size: 38px; color: #BD6760; transform: scale(1, 0.9)">
                              <span style="font-weight: 700;">Welcome back</span>
                            </div>
                          </div>
                          <span style="font-size: 14px; color: #c0c0c0">Welcome back login your account</span>
                        </div>
                        <div class="d-flex flex-column flex pt-4">
                          <v-form @submit="login" onSubmit="return false" class="d-flex flex-column flex-none"
                                  v-model="valid" ref="formLogin">
                            <div style="flex: none; padding-left: 40px; padding-right: 40px; padding-bottom: 50px;">
                              <span style="font-size: 22px; font-weight: bold; color: #dddddd">Email</span>
                              <div style="width: 100%;" class="pt-3">
                                <v-text-field
                                    light
                                    class="input-login"
                                    height="50"
                                    dense
                                    background-color="#00000042"
                                    solo
                                    flat
                                    v-model="email"
                                    label="Email"
                                    style="color: black !important;"
                                    :rules="[rules.requiredUserName]"
                                >
                                </v-text-field>
                              </div>
                              <span style="font-size: 22px; font-weight: bold; color: #dddddd;">Password</span>
                              <div style="width: 100%;" class="pt-3">
                                <v-text-field
                                    light
                                    class="input-login"
                                    height="50"
                                    dense
                                    background-color="#00000042"
                                    solo
                                    flat
                                    :type="isShowPassword ? 'text' : 'password'"
                                    v-model="password"
                                    label="Password"
                                    :append-icon="isShowPassword ? 'mdi-eye-off' : 'mdi-eye'"
                                    @click:append="isShowPassword = !isShowPassword"
                                    :rules="[rules.requiredPassword]"
                                >
                                </v-text-field>
                              </div>
                              <div style="width: 100%; height: 20px;">
                                <div class="fill-height d-flex justify-end">
                                  <ForgotPassword/>
                                </div>
                              </div>
                              <div style="width: 100%; text-align: center;" class="pt-5">
                                <v-btn :loading="loading" color="secondary" width="100%" height="50" type="submit"
                                       style="border-radius: 10.5px; text-transform: capitalize; font-weight: bold; font-size: 18px">Sign in
                                </v-btn>
                              </div>
                              <v-hover v-slot="{ hover }">
                                <div style="width: 100%; text-align: center; font-size: 18px; color: #dddddd"
                                     class="pt-5 mt-4">
                                  New User?
                                  <a @click="goToLink('/register')"
                                     :style="{'text-decoration': hover ? 'underline' : 'none'}"
                                     style="font-weight: bold; color: var(--v-secondary-base);">Register </a>
                                  here.
                                </div>
                              </v-hover>
                            </div>
                          </v-form>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </v-layout>
        </div>
      </div>
    </div>
  </v-app>
</template>

<script>
import {
  authLogin,
  checkToken
} from "@/backend"
import {mapState} from "@/store/ults"
import api from "@/api"
import ForgotPassword from "@/views/forgotPassword/ForgotDialog"
import Message from "@/components/Message"

export default {
  components: {
    Message,
    ForgotPassword
  },
  data: () => ({
    loading: false,
    valid: false,
    popupForgot: false,
    rules: {
      requiredUserName: value => (!!value && !!value.trim()) || 'Email is required',
      requiredPassword: value => (!!value && !!value.trim()) || 'Password is invalid',
    },
    email: undefined,
    password: undefined,
    isShowPassword: false
  }),
  computed: {
    ...mapState("auth", ["currentUser"]),
  },
  created() {
    const token = api.getToken()
    if (token) this.checkTokenExist()
  },
  methods: {
    async login() {
      this.$refs.formLogin.validate()
      if (!this.valid) return
      try {
        this.loading = true
        await authLogin({email: this.email, password: this.password})
        location.href = `${location.origin}/app`
      } catch (e) {
        // alert("Wrong password or account doesn't exist")
      } finally {
        this.loading = false
      }
    },
    async checkTokenExist() {
      try {
        const res = await checkToken()
        location.href = `${location.origin}/app`
      } catch (e) {
        console.log(e.message)
        api.deleteToken()
      }
    },
    goToLink(link) {
      if (this.$route.path !== link) this.$router.push({path: link});
    }
  }
};
</script>
<style scoped>
/deep/
.input-login .v-input__slot {
  /*box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.25);*/
  color: black !important;
}

/deep/
.input-login v-input {
  color: #333333 !important;
}

/deep/
.input-login input {
  color: #a8a8a8 !important;
}

/deep/
.input-login label {
  color: #a8a8a8 !important;
}

@media (prefers-reduced-motion: no-preference) {
  .square-animation {
    animation: wipe-enter 1s 1;
  }
}

.before-enter {
  opacity: 0;
  transform: scale(.5) rotateZ(-25deg);
  transition: all 1s ease-out;
}

.enter {
  opacity: 1;
  transform: scale(1) rotateZ(0deg);
}

.header {
  height: 800px;
}

.content {
  height: 100vh
}

.join-button {
  display: flex;
  min-width: 0;
  min-height: 0;
}

.left-info {
  display: flex;
  min-width: 0;
  min-height: 0;
}

@media only screen and (max-width: 925px) {
  .left-info {
    display: none;
  }
}

@media only screen and (max-width: 1100px) {
  .join-button {
    display: none;
  }
}

@media only screen and (max-height: 800px) {
  .header {
    height: 700px;
  }
}
</style>
<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}

.main-layout .custom-list-item {
  padding-left: 30px;
}

.flex {
  min-width: 0;
  min-height: 0;
}

.flex-none {
  flex: none;
}
</style>
<style>
html,
body,
.app {
}
</style>
