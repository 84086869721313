import Store from '@/store'
import config from '@/config.json'
import api from '@/api'
export async  function checkAuth (to, from, next) {
  try {
    if (to.query.token) {
      const token = to.query.token
      api.setToken(JSON.parse(window.atob(token)).access_token)
      next(to.path)
    }
    if (!api.getToken()) {
      throw "token"
    }
    let result = await api.get(`${config.backend}/auth/me`)
    Store.commit('auth/SET_USER', result.data)
  } catch (e) {
    // if (e === "token" || (e.response && e.response.status === 500)) {
    //   console.log(e)
    api.deleteToken()
    location.href = `${location.origin}/signIn`
    // }
  } finally {
    next()
  }
}
