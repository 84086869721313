import Vue from "vue";
import Vuetify from "vuetify";
import colors from "vuetify/es5/util/colors";
import en from 'vuetify/es5/locale/en'
import vi from './vi'

Vue.use(Vuetify);

const currentLocale = localStorage.getItem('locale') || 'en'
const currentDark = Boolean(localStorage.getItem('dark')) || true

const options = {
  icons: {
    iconfont: "mdi"
  },
  theme: {
    options: { customProperties: true },
    dark: currentDark,
    themes: {
      light: {
        bar: '#111418',
        primary: '#212930',
        accent: '#455a64',
        tab: '#455a64',
        secondary: '#f2f8fc',
        backgroundInput: '#F1F9FF',
        backgroundForm: '#f6fff6',
        backgroundList: '#212930',
        button: '#79899A',
        cancel: '#e8f5e9',
        info: '#058c0b',
        warning: '#ffc107',
        error: '#c41c00',
        success: colors.green.darken2
      },
      dark: {
        bar: '#181F26',
        primary: '#26303f',
        accent: '#212930',
        tab: '#2a343d',
        secondary: '#439aff',
        backgroundInput: '#F1F9FF',
        backgroundForm: '#f6fff6',
        backgroundList: '#181F26',
        button: '#79899A',
        block: '#212930',
        cancel: '#e8f5e9',
        info: '#3592FD',
        warning: '#ff0707',
        error: '#ff310d',
        success: '#228c00'
      }
    }
  },
  lang: {
    locales: {en, vi},
    current: currentLocale
  }
};

export const vuetify = new Vuetify(options);

export function toggleDark() {
  vuetify.framework.theme.dark = !vuetify.framework.theme.dark;
  localStorage.setItem('dark', vuetify.framework.theme.dark ? 'true': '')
}
