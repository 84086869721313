import Vue from 'vue'
import VueRouter from 'vue-router'
import MainLayout from '../views/MainLayout.vue'
import NotFound from '../views/NotFound'
import {checkAuth} from './auth'
import LandingPage from "@/views/landingPage/LandingPage"
import SignIn from "@/views/signIn/SignIn"
import ForgotPassword from "@/views/forgotPassword/ForgotPassword"
import Register from '@/views/register/Register'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    component: LandingPage,
  },
  {
    path: '/signIn',
    component: SignIn,
  },
  {
    path: '/register',
    component: Register,
  },
  {
    path: '/forgot',
    component: ForgotPassword,
  },
  {
    beforeEnter: checkAuth,
    path: '/app',
    component: MainLayout,
    children: [
      {
        path: '',
        name: 'Home',
        component: () => import('../views/Home.vue')
      }, {
        path: '/zoning',
        name: 'Home',
        component: () => import('../views/Home.vue')
      }, {
        path: '/disease-risk',
        name: 'disease-risk',
        component: () => import('../views/disease/Disease.vue')
      }, {
        path: 'user-management',
        name: 'User Management',
        component: () => import('../views/user/User.vue')
      }, {
        path: 'payment',
        name: 'Payment',
        component: () => import('../views/payment/PaymentLayout.vue')
      }, {
        path: 'farm-management',
        name: 'Farm Management',
        component: () => import('../views/Farm.vue')
      }, {
        path: 'profile',
        name: 'Profile',
        component: () => import('../views/user/MyUser.vue')
      }
    ]
  },
  {
    path: '*',
    component: NotFound,
    name: 'Not Found'
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
