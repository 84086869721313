
export default {
  namespaced: true,
  state: {
    menu: [{
      icon: 'mdi-view-dashboard-outline',
      label: 'DashBoard',
      path: '/app',
      id: 'dashboard'
    }, {
      icon: 'mdi-leaf',
      label: 'Zoning',
      path: '/zoning',
      id: 'zoning'
    }, {
      icon: 'mdi-virus-outline',
      label: 'Disease risk',
      path: '/disease-risk',
      id: 'disease-risk'
    }],
    group: {
      icon: 'mdi-view-dashboard-outline',
      label: 'DashBoard',
      path: '/app',
      id: 'dashboard'
    }
  },

  mutations: {
  }
}
