<template>
  <v-btn v-bind="$attrs" :loading="loading" color="error" @click="logout">
    <v-icon class="mr-1">mdi-logout-variant</v-icon>
    {{$t('Logout')}}
  </v-btn>
</template>

<script>

import { authLogout } from '@/backend'

export default {
  name: "Logout",
  data() {
    return {
      loading: false
    };
  },

  methods: {
    async logout() {
      await authLogout()
    }
  }
};
</script>
