const IntroStep = {
  getStepIntroInList(isFirstAction) {
    let steps = [
      {
        element: document.querySelector('.welcome'),
        intro: `
              <div style="width: 100%; height: 250px">
                <img alt="welcome" src="/welcome.jpg" width="100%" height="auto" style="object-fit: cover">
              </div>
              `
      }
    ]
    steps.push({
      element: document.querySelector(`.dashboard`),
      intro: 'You can manage all the fields with this screen'
    })
    steps = steps.concat([{
      element: document.querySelector('.field-control'),
      intro: 'You can create a new the field and see all the fields created'
    }, {
      element: document.querySelector('.map-view'),
      intro: 'On the map, you can see the fields and images from time to time'
    }, {
      element: document.querySelector('.connect-to-survey'),
      intro: 'You can connect and import field from EOF Survey'
    }])
    if (isFirstAction) {
      steps = steps.concat([{
        element: document.querySelector('.fist-create-field'),
        intro: 'In the first action, you need to add a new field to monitor'
      }, {
        element: document.querySelector('.btn-create-field'),
        intro: 'Or, you can click to open create field form'
      },  {
        element: document.querySelector('.create'),
        intro: 'You can select one of the options for creating a field'
      }])
    } else if (document.querySelector('.btn-create-field')) {
      steps = steps.concat([{
        element: document.querySelector('.btn-create-field'),
        intro: 'You can click to open create field form'
      }, {
        element: document.querySelector('.das'),
        intro: 'Once you create a field, you can view it in the list'
      }])
    }
    return steps
  },
  getStepIntroInDetail() {
    return [
      {
        element: document.querySelector('.farm-0'),
        intro: "Click to select field",
        position: 'bottom'
      }, {
        element: document.querySelector('.das'),
        intro: 'Once you select a field, you can view its analytics'
      }
    ]
  }
}
export default IntroStep
