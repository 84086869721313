<template>
  <v-app id="inspire" class="main-layout" style="height: 100vh; position: relative">
    <div style="position: absolute; bottom: 0; right: 0px; z-index: 401">
      <Feedback :isShow.sync="showFeedback"/>
    </div>
    <Message/>
    <v-navigation-drawer
        style="z-index: 999"
        mini-variant-width="80"
        :mini-variant="miniSize || screenWidth < 1400"
        permanent
        left
        v-model="drawer"
        app
        class="pt-4"
        color="bar"
        mobile-breakpoint=""
    >
      <v-layout column align-center class="fill-height">
        <div style="width: 100%; height: 45px">
          <v-layout align-center justify-center>
            <img alt="logo" :src="miniSize ? require('@/assets/images/Logo_EO_Factory_purple.png') : require('@/assets/images/logo-white-2a642acb6b7ceb83fae385c1c7524d5f.png')"
                 style="max-width: 90%; height: 40px"/>
          </v-layout>
        </div>
        <v-list width="100%"
                nav
                dense
        >
          <v-list-item-group
              v-model="group"
              mandatory
              active-class="text--primary"
          >
            <v-list-item v-for="(item, index) in menu" :key="index" :value="item" :class="item.id"
                         @click="goToLink(item)">
              <v-list-item-icon>
                <v-icon :color="item === group ? 'secondary' : 'white'">{{ item.icon }}</v-icon>
              </v-list-item-icon>
              <v-list-item-title style="color: white !important;">{{ item.label }}</v-list-item-title>
            </v-list-item>

          </v-list-item-group>
        </v-list>
        <v-layout style="height: calc(100% - 125px); width: 100%" column align-center justify-end>
          <v-list width="100%"
                  nav
                  dense
          >
            <v-list-item-group
                mandatory
                active-class="text--primary"
            >
              <v-tooltip bottom color="#000000">
                <template v-slot:activator="{ on, attrs }">
                  <v-list-item v-bind="attrs" v-on="on"
                               @click="() => !currentUser.survey_linked ? $refs.linkForm.openDialog() : ''">
                    <v-list-item-icon>
                      <v-icon :color="currentUser.survey_linked ? 'secondary' : 'white'">mdi-link-variant</v-icon>
                    </v-list-item-icon>
                    <v-list-item-title style="color: white !important;">Connect to Survey</v-list-item-title>
                  </v-list-item>
                </template>
                <span v-if="currentUser.survey_linked">Connected to Survey</span>
                <span v-else>Connect to https://survey.eofactory.ai</span>
              </v-tooltip>

              <v-list-item @click="showFeedback = !showFeedback">
                <v-list-item-icon>
                  <v-icon :color="showFeedback ? 'secondary' : 'white'">mdi-thumb-up</v-icon>
                </v-list-item-icon>
                <v-list-item-title style="color: white !important;">Feedback</v-list-item-title>
              </v-list-item>

              <v-list-item @click="startIntro">
                <v-list-item-icon>
                  <v-icon>mdi-help-box</v-icon>
                </v-list-item-icon>
                <v-list-item-title style="color: white !important;">Help</v-list-item-title>
              </v-list-item>

              <v-list-item @click="$router.push('/')">
                <v-list-item-icon>
                  <v-icon color="white">mdi-home</v-icon>
                </v-list-item-icon>
                <v-list-item-title style="color: white !important;">Home page</v-list-item-title>
              </v-list-item>
              <v-list-item style="height: 60px; border-top: 1px solid #9ea5b0; padding-top: 4px"
                           @click="$router.push('/app/profile')">
<!--                  <img-->
<!--                      :src="currentUser && currentUser.avatar ? transformUrl(currentUser.avatar) : require('@/assets/images/default-avatar.jpg')"-->
<!--                      alt="John"-->
<!--                  >-->
                  <AvatarPopOver/>
                <v-layout column class="fill-height" justify-center align-start>
                  <span style="color: white !important;">{{ currentUser.name }}</span>
                  <v-btn x-small color="error" @click.native.stop="logout">
                      <v-icon size="13" color="white">mdi-logout</v-icon>{{ $t('Logout') }}
                    </v-btn>
                </v-layout>
              </v-list-item>
              <v-list-item style="min-height: 0; height: 30px !important;" @click="miniSize = !miniSize" v-if="screenWidth > 1400">
                <v-layout align-center justify-center>
                  <v-icon color="white">{{miniSize ? 'mdi-chevron-right' : 'mdi-chevron-left'}}</v-icon>
                </v-layout>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-layout>
      </v-layout>
    </v-navigation-drawer>
    <v-main class="d-flex" style="height: calc(100% - 80px)">
      <router-view :key="$route.fullPath"></router-view>
    </v-main>
    <LinkToSurvey ref="linkForm"/>
  </v-app>
</template>

<script>
import axios from "axios"
import {mapState} from "@/store/ults"
import AvatarPopOver from "@/components/AvatarPopOver"
import Language from "@/components/Language"
import {toggleDark} from "@/plugins/vuetify"
import Message from "@/components/Message"
import config from "@/config.json"
import LinkToSurvey from "@/components/home/link/LinkToSurvey"
import Feedback from "@/components/home/feedback/Feedback"
import IntroStep from "@/ultis/introStep"
import sleep from "@/ultis/sleep"
import Logout from "@/components/LogoutButton";
import {authLogout} from "@/backend";

export default {
  components: {
    Logout,
    Feedback,
    LinkToSurvey,
    Message,
    AvatarPopOver,
    Language,
  },
  data: () => ({
    showFeedback: false,
    dialog: false,
    drawer: false,
    miniSize: true,
    keyWord: undefined,
    screenWidth: window.screen.width
  }),
  computed: {
    ...mapState("main", ["menu", "group"]),
    ...mapState("auth", ["currentUser"]),
    ...mapState('tabs', ['tabs'])
  },
  async created() {
  },
  mounted() {
    this.group = this.menu.find(val => val.path === this.$route.path)
  },
  watch: {
    group() {
      this.tabs = [{
        closAble: false,
        icon: 'mdi-view-dashboard-outline',
        name: 'All field',
        id: undefined
      }]
    }
  },
  methods: {
    async logout() {
      await authLogout()
    },
    transformUrl(url) {
      return config.host + url
    },
    startIntro() {
      let isFirstAction = !!document.querySelector('.fist-create-field')
      let steps = IntroStep.getStepIntroInList(isFirstAction)
      this.intro.setOptions({
        disableInteraction: true,
        overlayOpacity: 0.8,
        exitOnOverlayClick: false,
        showBullets: false,
        steps: steps
      }).onbeforechange(e => {
        if (!isFirstAction && this.intro._currentStep === steps.length - 1 && document.querySelector('.farm-0')) {
          this.selectField()
        } else if (isFirstAction && this.intro._currentStep === steps.length - 1) {
          document.querySelector('.btn-create-field').click()
        }
      }).oncomplete(() => {
      }).onexit(() => {
      }).start()
    },
    async selectField() {
      this.intro.exit()
      await sleep(100)
      let steps = IntroStep.getStepIntroInDetail()
      this.intro.setOptions({
        disableInteraction: true,
        overlayOpacity: 0.8,
        exitOnOverlayClick: false,
        showBullets: false,
        steps: steps
      }).onbeforechange(e => {
        if (this.intro._currentStep === steps.length - 1) {
          document.querySelector('.farm-0').click()
        }
      })
      this.intro.start()
    },
    async getTokenWeather() {
      try {
        const res = await axios.get(`${config.weatherHost}/Auth/Token?username=admin&password=1`)
        if (localStorage.getItem('weather_token')) localStorage.removeItem('weather_token')
        localStorage.setItem('weather_token', res.data.token)
      } catch (e) {
      }
    },
    goToLink(menu) {
      if (this.$route.path !== menu.path) this.$router.push({path: menu.path});
    },
    toggleDark,
  },
};
</script>

<style>
.custom-nav .v-navigation-drawer__content {
  overflow-y: hidden;
}

.main-layout .v-list-group__header .v-list-item {
  padding-left: 0;
}

.main-layout .custom-list-item {
  padding-left: 30px;
}

.main-header .v-toolbar__content {
}

.search-input .v-input__slot {
  border-color: red !important;
  min-height: 0 !important;
}

.v-select__selection {
  font-size: 12px !important;
  color: white !important;
}

.v-main__wrap {
  display: flex;
}
</style>
<style>
html,
body,
.inspire {
  overflow-y: hidden;
  font-family: "Roboto", sans-serif;
}

#bound-menu {
  background-color: transparent;
  z-index: 6;
  width: 40px;
  height: 40px;
  position: absolute;
  top: 20px;
  right: 0px
}

#menu {
  z-index: 6;
  border-radius: 50%;
  width: 40px;
  height: 40px;
  background-color: var(--v-backgroundForm-base);
}
</style>
